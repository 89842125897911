import React from "react"
import Layout from "@/templates/layout"
import { Helmet } from "react-helmet"

const Index = (props: any) => {
  return <Layout {...props} seo={{prefixes: []}} title="Crypto Exchanges">
    <Helmet>
      <meta name={`robots`} content={`noindex, nofollow`} />
    </Helmet>
    <div className="container container-small">
      <div>
        <div id="cookiefirst-policy-page"></div>

        <div id="cookiefirst-cookies-table"></div>
      </div>
    </div>
  </Layout>
}

export default Index